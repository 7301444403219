import type { Day, MealType, PartialWeeklyMenu, WeeklyConfig } from "./constants";
import { COOK, LUNCHBOX, MEAL_TYPES, WEEKEND, daysOfWeek } from "./constants";

export const isLunchboxNeeded = (config: WeeklyConfig, day?: Day) => {
  if (config[LUNCHBOX]) {
    if (day) {
      return !WEEKEND.includes(day);
    }
    return true;
  }
  return false;
};

export const isMealTypeNeeded = (config: WeeklyConfig, day: Day | undefined, type: MealType): boolean => {
  if (type === LUNCHBOX) {
    return isLunchboxNeeded(config, day);
  }
  if (day) {
    return config.daily[day][type] === COOK;
  } else {
    return daysOfWeek.some((d) => config.daily[d][type] === COOK);
  }
};

export const isAnyMealTypeNeeded = (config: WeeklyConfig, day: Day) => {
  return MEAL_TYPES.some((type) => isMealTypeNeeded(config, day, type));
};

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const ret: any = {};
  keys.forEach((key) => {
    ret[key] = obj[key];
  });
  return ret;
}

export function isValidMenu(menu: PartialWeeklyMenu, config: WeeklyConfig) {
  for (const day of daysOfWeek) {
    for (const type of MEAL_TYPES) {
      if (isMealTypeNeeded(config, day, type) && !menu[day]?.[type]) {
        return false;
      }
    }
  }
  return true;
}

export function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}
