import { Link } from "@remix-run/react";
import clsx from "clsx";
import deepEqual from "deep-equal";
import { memo } from "react";
import { isAnyMealTypeNeeded, isMealTypeNeeded } from "~/utils";
import type { DailyMenu, Day, Meal, PartialWeeklyMenu, WeeklyConfig } from "~/utils/constants";
import { LIKE, MEAL_TYPES, daysOfWeek } from "~/utils/constants";
import { LikeButton } from "./LikeButton";

export function MealCardLayout({
  title,
  children,
  className,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={clsx("relative", className)}>
      <div className="flex flex-row justify-between mb-2">
        <div className="items-center self-center font-medium text-base-content">{title}</div>
      </div>
      {children}
    </div>
  );
}

export function RecipeLink({
  recipeId,
  className,
  children,
}: {
  recipeId: string;
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <Link className={clsx("link no-underline", className)} to={`/recipe/${recipeId}`}>
      {children}
    </Link>
  );
}

export const MealCard = memo(function MealCard({
  meal,
  inProgress,
  hideActions,
  actions,
}: {
  meal?: Meal;
  inProgress: boolean;
  hideActions?: boolean;
  actions?: React.ReactNode; // Override default actions
}) {
  if (!meal) {
    if (inProgress) {
      return <Skeleton />;
    } else {
      return (
        <div role="alert" className="alert alert-warning">
          <span>Failed to load meal</span>
        </div>
      );
    }
  }
  const title = meal.title;
  const description = <p className="mb-3 mt-0">{meal.description}</p>;

  return (
    <MealCardLayout
      title={
        hideActions ? (
          title
        ) : (
          <RecipeLink recipeId={meal.recipeId} className="hover:text-primary">
            {title}
          </RecipeLink>
        )
      }
    >
      {hideActions ? (
        description
      ) : (
        <RecipeLink className="not-prose" recipeId={meal.recipeId}>
          {description}
        </RecipeLink>
      )}
      {!hideActions && (
        <div className="card-actions justify-between md:justify-start">
          {meal.recipeId ? actions || <LikeButton recipeId={meal.recipeId} liked={meal.reaction === LIKE} /> : null}
        </div>
      )}
    </MealCardLayout>
  );
}, deepEqual);

function DailyMenuLayout({ day, children }: { day: Day; children: React.ReactNode }) {
  return (
    <div className="prose mt-4" id={day}>
      <div className="divider sticky top-0 z-10 bg-base-100 my-0 py-8 uppercase text-primary divider-primary">
        {day}
      </div>
      <div className="space-y-10">{children}</div>
    </div>
  );
}
export function DailyMenuRender({
  day,
  menu,
  config,
  week,
  inProgress,
  hideActions,
}: {
  day: Day;
  menu?: DailyMenu;
  config: WeeklyConfig;
  week?: string;
  inProgress: boolean;
  hideActions?: boolean;
}) {
  if (!isAnyMealTypeNeeded(config, day)) return null;
  return (
    <DailyMenuLayout day={day}>
      {MEAL_TYPES.map((type) => {
        if (!isMealTypeNeeded(config, day, type)) return null;
        return <MealCard key={type} meal={menu?.[type]} inProgress={inProgress} hideActions={hideActions} />;
      })}
    </DailyMenuLayout>
  );
}
function Skeleton() {
  return (
    <MealCardLayout title={<div className="skeleton h-6 w-2/3" />}>
      <div className="skeleton h-36 w-full"></div>
    </MealCardLayout>
  );
}

export function WeeklyMenuRender({
  menu,
  config,
  inProgress,
  week,
  hideActions,
}: {
  menu: PartialWeeklyMenu;
  config: WeeklyConfig;
  inProgress: boolean;
  week?: string;
  hideActions?: boolean;
}) {
  return (
    <>
      {daysOfWeek.map((day) => (
        <DailyMenuRender
          key={day}
          day={day}
          menu={menu[day]}
          week={week}
          config={config}
          inProgress={inProgress}
          hideActions={hideActions}
        />
      ))}
    </>
  );
}
